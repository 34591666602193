<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalCreateTeam" :title="$t('create')" @shown="init">
      <div class="form-group">
        <label for="name">{{ $t('name') }} <small class="text-muted">{{ $t('only15Letters') }}</small></label>
        <b-form-input v-model="team.name" id="name" name="name" v-validate="'required|max:15|min:3'" :state="validateState('name')" />
        <span class="error-inputs">{{ errors.first('name') }}</span>
      </div>
      <div class="form-group">
        <label for="country">{{$t('country')}}</label>
        <multiselect v-validate="'required'" :state="validateState('country')" name="country" id="country" v-model="team.country" :options="availableCountries" deselectLabel="" track-by="name" label="name" :placeholder="$t('selectOption')" :searchable="true" :allow-empty="false" selectLabel="" selectedLabel="">
          <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
        </multiselect>
        <span class="error-inputs">{{ errors.first('country') }}</span>
      </div>
      <div class="form-group">
        <label for="console">{{$t('console')}}</label>
        <multiselect v-validate="'required'" :state="validateState('console')" name="console" id="console" v-model="team.console" :options="consoles" deselectLabel="" :placeholder="$t('selectOption')" :searchable="false" selectLabel="" selectedLabel="">
        </multiselect>
        <span class="error-inputs">{{ errors.first('console') }}</span>
      </div>
      <div class="form-group">
        <label for="mode">{{ $t('mode') | toCapitalize }}</label>
        <select name="mode" id="mode" class="form-control" v-validate="'required'" :state="validateState('console')" v-model="team.mode">
          <option value="normal">Pro</option>
          <option value="1vs1">1 vs 1</option>
        </select>
        <span class="error-inputs">{{ errors.first('console') }}</span>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalCreateTeam')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      team: {},
      availableCountries: [],
      consoles: []
    }
  },
  computed: {
    ...mapGetters(['lang', 'console', 'countries'])
  },
  mounted () {
    this.availablePositions = window.positions
    this.consoles = window.consoles
    this.team.console = 'PSN'
  },
  methods: {
    fetchCountries () {
      this.$axios.get('countries').then((response) => {
        const data = response.data.data
        this.$store.dispatch('SET_COUNTRIES', data)
        this.setCountriesForSelect(data)
      })
    },
    setCountriesForSelect (countries) {
      this.availableCountries = countries.map((country) => {
        return {
          id: country.id,
          name: country.name
        }
      })
    },
    init () {
      if (this.countries.length < 1) {
        this.fetchCountries()
      } else {
        this.setCountriesForSelect(this.countries)
      }
      this.team = {}
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = this.team
          payload.country_id = this.team.country.id
          const path = `auth/${this.lang}/${this.console}/teams/store`
          this.$axios.post(path, payload).then(response => {
            this.$store.dispatch('SET_ADD_TEAM', response.data.team)
            this.$toastr.success(response.data.message)
            this.$root.$emit('bv::hide::modal', 'modalCreateTeam')
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
