<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalChangeName" :title="$t('rename')">
      <div class="form-group">
        <label for="name">{{ $t('name') }}</label>
        <b-form-input type="text" id="name" v-model="team.name"></b-form-input>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalChangeName')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    team: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      name
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  methods: {
    save () {
      const payload = {
        name: this.team.name
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/change-name`
      this.$axios.put(path, payload).then((response) => {
        this.$store.dispatch('SET_CHANGE_NAME_TEAM', this.team)
        this.$toastr.success(response.data.message)
        this.$root.$emit('bv::hide::modal', 'modalChangeName')
      })
    }
  }
}
</script>
