<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{$t('myTeams')}}</h5>
      <div class="btns">
        <b-form-input type="search" v-model="filter" :placeholder="$t('search')" autofocus @keyup.esc="clearFilter" />
        <b-button @click="openModalCreate()">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="teams" :fields="columns" :filter="filterForUsers">
      <div slot="name" slot-scope="row">
        <router-link :to="{ name: 'TeamBackManagment', params: { lang: lang, console: console, teamId: row.item.id } }">{{ row.item.name }}</router-link>
      </div>
      <div slot="quantity" slot-scope="team" class="text-right">{{ team.value }}</div>
      <template slot="actions" slot-scope="team">
        <div class="w-100 text-center">
          <b-dropdown id="ddTeamOptions" right noCaret>
            <div slot="button-content" class="px-2">
              <i class="fa fa-ellipsis-v"></i>
            </div>
            <b-dropdown-item v-if="user.type === 'SUPER'" @click="openModalChangeName(team.item)">{{ $t('rename') }}</b-dropdown-item>
            <b-dropdown-item @click="openModalChangeImage(team.item)">{{ $t('changeImage') }}</b-dropdown-item>
            <b-dropdown-item @click="toNotices(team.item)">{{ $t('notices') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <b-pagination align="center" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
    <modal-create />
    <modal-change-image />
    <modal-change-name :team="currentTeam" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
const debounce = require('lodash.debounce')
import ModalCreate from './ModalCreate'
import ModalChangeImage from './ModalAddImage'
import ModalChangeName from './partials/ModalChangeName'

export default {
  components: {
    ModalCreate,
    ModalChangeImage,
    ModalChangeName
  },
  data () {
    return {
      filterForUsers: '',
      totalRows: 1,
      perPage: 25,
      currentPage: 1,
      filter: '',
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'quantity', label: 'Premium', sortable: true },
        { key: 'actions', label: '' }
      ],
      debounceFn: null,
      hideShowModalChangeImage: false,
      hideShowModalAddTeam: false,
      hideShowModalMoney: false,
      currentTeam: {}
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'teams',
      'team',
      'user',
      'console'
    ])
  },
  mounted() {
    const page = this.$route.query.page
    if (!page) {
      this.currentPage = 1
      this.$router.push({ name: 'TeamsBack', params: { lang: this.lang, console: this.console }, query: { page: 1 } })
    } else {
      this.currentPage = page
    }
    this.changeTitleBar(this.$t('myTeams'))
    if (this.teams.length < 1) {
      this.fetchData()
    }
  },
  watch: {
    filter () {
      this.debounceFn()
    },
    currentPage () {
      this.$router.push({ name: 'TeamsBack', params: { lang: this.lang, console: this.console }, query: { page: this.currentPage } })
      this.updateData()
    }
  },
  created () {
    this.debounceFn = debounce(() => {
      this.updateData()
    }, 1000)
  },
  methods: {
    openModalChangeName (team) {
      this.currentTeam = JSON.parse(JSON.stringify(team))
      this.$root.$emit('bv::show::modal', 'modalChangeName')
    },
    toNotices (team) {
      this.$store.dispatch('SET_TEAM', team)
      this.$router.push({ name: 'TeamBackNotices', params: { lang: this.lang, console: this.console, teamId: team.id } })
    },
    openModalChangeImage (team) {
      this.$store.dispatch('SET_TEAM', team)
      this.$root.$emit('bv::show::modal', 'modalChangeImageTeam')
    },
    openModalCreate () {
      this.$root.$emit('bv::show::modal', 'modalCreateTeam')
    },
    clearFilter () {
      this.filter = ''
      this.filterForUsers = ''
    },
    search () {
      if (this.user.type === 'SUPER') {
        this.fetchData()
      } else {
        this.filterForUsers = this.filter
      }
    },
    fetchData (params = {}) {
      const path = `auth/${this.lang}/${this.console}/teams/list`
      this.$axios.get(path, { params }).then((response) => {
        const data = response.data.data
        this.perPage = data.per_page
        this.totalRows = data.total
        this.$store.dispatch('SET_TEAMS', data.data)
      })
    },
    updateData () {
      let params = {}
      if (this.filter.length > 0) {
        params.q = this.filter
        if (this.filter.length === 1) {
          params.page = 1
        }
      }
      params.page = this.currentPage
      this.fetchData(params)
    }
  }
}
</script>
